import Cookies from 'js-cookie';

// App 启动项目
const IsEnableRunKey = 'IsEnableRun';
export const getIsEnableRun = () => Cookies.get(IsEnableRunKey);
export const setIsEnableRun = (sidebarStatus: string) => Cookies.set(IsEnableRunKey, sidebarStatus);

// User
const tokenKey = 'admin_token';
export const getToken = () => Cookies.get(tokenKey);
export const setToken = (token: string) => Cookies.set(tokenKey, token, { path: '/' });
export const removeToken = () => Cookies.remove(tokenKey);

// UserData
const userData = 'cms_user';
export const getUserData = () => Cookies.getJSON(userData);
export const setUserDatan = (token: object) => Cookies.set(userData, token, { path: '/' });
export const removeUserData = () => Cookies.remove(userData);

// 运费计算模块
const freightKey = 'freight';
export const getFreight = () => Cookies.getJSON(freightKey);
export const setFreight = (data: object) => Cookies.set(freightKey, data);
export const removeFreight = () => Cookies.remove(freightKey);

// 店铺配置
const shopConfigKey = 'shopConfig';
export const getShopConfig = () => Cookies.getJSON(shopConfigKey);
export const setShopConfig = (data: object) => Cookies.set(shopConfigKey, data, { path: '/' });

// 注册来源
const SourceKey = 'RegisteredSource';
export const getSource = () => Cookies.get(SourceKey);
export const setSource = (data: string) => Cookies.set(SourceKey, data, { path: '/' });

// 代理来源
const AgentClientIdKey = 'AgentClientIdKey';
export const getAgentClient = () => Cookies.get(AgentClientIdKey);
export const setAgentClient = (data: string) => Cookies.set(AgentClientIdKey, data, { path: '/' });

// 配置
const adminConfig = 'admin_config';
export const getAdminConfig = () => Cookies.getJSON(adminConfig);
export const setAdminConfig = (token: object) => Cookies.set(adminConfig, token, { path: '/' });
export const removeAdminConfig = () => Cookies.remove(adminConfig);
